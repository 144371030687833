import { z } from 'zod';
import { StringToDateSchema } from '../common';

export enum EMongoDbCollection {
  products = 'products',
  orders = 'orders',
  users = 'users',
  stores = 'stores',
  events = 'events',
  notifications = 'notifications',
  companySettings = 'companySettings',
  userPreferences = 'userPreferences',
}

export enum EMongoDbFunction {
  upsertUserDocsOnLogin = 'upsertUserDocsOnLogin',
}

export const getMongodbStatusHistorySchema = <T extends z.ZodTypeAny>(statusSchema: T) => {
  return z.object({
    updatedOn: StringToDateSchema,
    serverUpdatedOn: StringToDateSchema,
    updatedFrom: statusSchema,
    updatedBy: z.object({
      name: z.string(),
      email: z.string(),
      code: z.string().optional(),
      initial: z.string().optional(),
    }),
    updatedTo: statusSchema,
  });
};

export const BopisSettingsEarliestPickupTimeFormat = 'h:mm A';

export const BopisSettingsPickupBy = ['Schedule', 'When Ready'] as const;

export const EBopisSettingsPickupBy = z.enum(BopisSettingsPickupBy);

export type EBopisSettingsPickupBy = z.infer<typeof EBopisSettingsPickupBy>;

export const BopisSettingsEarliestPickupDay = [
  'Same Day',
  'Next Day',
  '2 Days From Order Placement',
] as const;

export const EBopisSettingsEarliestPickupDay = z.enum(BopisSettingsEarliestPickupDay);

export type EBopisSettingsEarliestPickupDay = z.infer<typeof EBopisSettingsEarliestPickupDay>;

export const BopisSettingsUsuallyReadyIn = [
  '1 Hour',
  '2 Hours',
  '4 Hours',
  '24 Hours',
  '2-4 Days',
] as const;

export const EBopisSettingsUsuallyReadyIn = z.enum(BopisSettingsUsuallyReadyIn);

export type EBopisSettingsUsuallyReadyIn = z.infer<typeof EBopisSettingsUsuallyReadyIn>;

export const BopisSettingsPickupSlotDuration = ['15 Mins', '30 Mins', '1 Hour', '2 Hours'] as const;

export const EBopisSettingsPickupSlotDuration = z.enum(BopisSettingsPickupSlotDuration);

export type EBopisSettingsPickupSlotDuration = z.infer<typeof EBopisSettingsUsuallyReadyIn>;
