export const REALM_ACCESS_TOKEN_HEADER_TEXT = 'x-realm-access-token';
export const FIND_ORDERS_PAGE_LIMIT = 10;
export const FAILED_PAYMENT_RETRY_LIMIT = 1;
export const PACKING_DELAYED_WARNING_IN_HOURS = 3;
export const PHANTOM_DISTRIBUTION_CENTER = {
  company: '002',
  locationCode: 'WARN',
};
export const DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const;
export const PACKER_CODE_MAX_LENGTH = 4;
export const PACKER_CODE_MIN_LENGTH = 3;
export const PACKER_INITIALS_MAX_LENGTH = 100;
export const PACKER_INITIALS_MIN_LENGTH = 2;

export const DELIVERY_PERSON_CODE_MAX_LENGTH = 4;
export const DELIVERY_PERSON_CODE_MIN_LENGTH = 3;
export const DELIVERY_PERSON_INITIALS_MAX_LENGTH = 100;
export const DELIVERY_PERSON_INITIALS_MIN_LENGTH = 2;
