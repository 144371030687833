import {
  IMongoDbOrderStatus,
  IMongodbOrder,
  IMongodbOrderFulfillmentStatus,
  MongoDbOrderStatusEnum,
} from '../../types/mongodb';
import { actionCreator, asyncActionCreator } from '../helper/index';
import {
  IShopifyOrder,
  ISubstitutesProduct,
  ShopifyBopisStatusEnum,
} from '../../types/shopify/TypeOrder';
import {
  IFetchedOrdersForPages,
  IFindOrdersQuery,
  IOrder,
  IStoreOrdersCount,
} from '../../types/fulfillments/Order';

enum OrdersActionTypes {
  GET_ORDERS_FOR_STORE = 'ORDERS/GET_ORDERS_FOR_STORE',
  ORDER_COUNT_CHANGES = 'ORDERS/COUNT_CHANGES',
  SET_ORDER = 'ORDERS/SET_ORDER',
  UPDATE_MONGODB_ORDER = 'ORDERS/UPDATE_MONGODB_ORDER',
  GET_ORDER = 'ORDERS/GET_ORDER',
  SET_ORDERS = 'ORDERS/SET_ORDERS',
  REMOVE_ORDER = 'ORDERS/REMOVE_ORDER',
  SET_SUBSTITUTES_PRODUCTS = 'ORDERS/SET_SUBSTITUTES_PRODUCTS',
  UPDATE_ORDERS_STATUS_HISTORY = 'ORDERS/UPDATE_ORDERS_STATUS_HISTORY',
  UPDATE_PRINTING_SUMMARY = 'ORDERS/UPDATE_PRINTING_SUMMARY',
  UPDATE_ORDERS_FULFILLMENT_STATUS = 'ORDERS/UPDATE_ORDERS_FULFILLMENT_STATUS',
  MARK_ORDER_READY_FOR_PICKUP = 'ORDERS/MARK_ORDER_READY_FOR_PICKUP',
  GET_SUBSTITUTES_PRODUCTS = 'ORDERS/GET_SUBSTITUTES_PRODUCTS',
  MARK_ORDER_FULFILLED = 'ORDERS/MARK_ORDER_FULFILLED',
  GET_ORDERS_COUNT = 'ORDERS/GET_ORDERS_COUNT',
  UPDATE_ORDERS_COUNT = 'ORDERS/UPDATE_ORDERS_COUNT',
  UPDATED_FETCHED_ORDERS_FOR_PAGES = 'ORDERS/UPDATED_FETCHED_ORDERS_FOR_PAGES',
  FIND_ORDERS = 'ORDERS/FIND_ORDERS',
  RESET_ORDERS = 'ORDERS/RESET',
}

export const getOrdersForStore = asyncActionCreator<
  Map<string, IOrder>,
  {
    locationCode: string;
    status: IMongoDbOrderStatus;
  }
>(OrdersActionTypes.GET_ORDERS_FOR_STORE);
export const watchOnOrders = asyncActionCreator<undefined, { locationCode: string }>(
  OrdersActionTypes.ORDER_COUNT_CHANGES,
);
export const getOrder = asyncActionCreator<IOrder, { id: string }>(OrdersActionTypes.GET_ORDER);
export const setOrder = actionCreator<{ order: IOrder }>(OrdersActionTypes.SET_ORDER);
export const updateMongodbOrder = actionCreator<{
  mongodbOrder?: IMongodbOrder;
  shopifyOrder?: IShopifyOrder;
}>(OrdersActionTypes.UPDATE_MONGODB_ORDER);
export const removeOrder = actionCreator<{ orderId: string }>(OrdersActionTypes.REMOVE_ORDER);
export const setProductsSubstitutes = actionCreator<{
  orderId: string;
  productId: string;
  substitutesProducts: ISubstitutesProduct[];
}>(OrdersActionTypes.SET_SUBSTITUTES_PRODUCTS);
export const updateOrdersStatusHistory = asyncActionCreator<
  {
    status: IMongoDbOrderStatus;
  },
  {
    bopisStatus: ShopifyBopisStatusEnum;
    orderId: string;
    updatedFrom: IMongoDbOrderStatus;
    updatedTo: IMongoDbOrderStatus;
    locationCode: string;
    packerCode?: string;
    packerInitial?: string;
  }
>(OrdersActionTypes.UPDATE_ORDERS_STATUS_HISTORY);
export const updateOrdersPrintingSummary = asyncActionCreator<
  undefined,
  { invoice: boolean; waiverForm: boolean; packingSlip: boolean; orderId: string }
>(OrdersActionTypes.UPDATE_PRINTING_SUMMARY);
export const updateOrdersFulfillmentStatus = asyncActionCreator<
  undefined,
  { fulfillmentStatus: IMongodbOrderFulfillmentStatus; orderId: string; locationCode: string }
>(OrdersActionTypes.UPDATE_ORDERS_FULFILLMENT_STATUS);
export const markOrderReadyForPickup = asyncActionCreator<
  undefined,
  {
    fulfillmentOrderId: string;
    orderId: string;
    totalQty: number;
    packerInitial: string;
    packerCode: string;
  }
>(OrdersActionTypes.MARK_ORDER_READY_FOR_PICKUP);
export const getSubstitutesProduct = asyncActionCreator<
  undefined,
  {
    productId: string;
    price?: number;
    productType?: string;
    orderId: string;
    nodes?: string;
    locationCode: string;
  }
>(OrdersActionTypes.GET_SUBSTITUTES_PRODUCTS);
export const markOrderFulfilled = asyncActionCreator<
  any,
  { orderId: string; locationCode: string; updatedFrom: MongoDbOrderStatusEnum }
>(OrdersActionTypes.MARK_ORDER_FULFILLED);
export const getOrdersCount = asyncActionCreator<IStoreOrdersCount, { locationCode: string }>(
  OrdersActionTypes.GET_ORDERS_COUNT,
);
export const updateOrdersCount = actionCreator<{
  updatedFrom?: IMongoDbOrderStatus;
  updatedTo?: IMongoDbOrderStatus;
}>(OrdersActionTypes.UPDATE_ORDERS_COUNT);
export const updateFetchedOrdersForPages = actionCreator<{
  key: keyof IFetchedOrdersForPages;
  value: boolean;
}>(OrdersActionTypes.UPDATED_FETCHED_ORDERS_FOR_PAGES);
export const findOrders = asyncActionCreator<
  { orders: IOrder[]; count: number },
  {
    queries: Partial<IFindOrdersQuery>;
    locationCode: string;
    startValue?: Date;
    sortKey?: string;
    reset?: boolean;
    sortBy?: 'ASC' | 'DSC';
  }
>(OrdersActionTypes.FIND_ORDERS);
export const resetOrders = actionCreator(OrdersActionTypes.RESET_ORDERS);
